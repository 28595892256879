import React from "react";
import { Link } from "./Link";
import { Header } from "./Header";

interface portfolioPostProps {
    id: string;
    title: string;
    category: string;
    date: string;
    image: string;
    altText: string;
    description: string;
    link: string;
}

export const PortfolioCard: React.FC<portfolioPostProps> = ({
    title,
    category,
    date,
    image,
    altText,
    description,
    link,
}) => {
    const isProject = category === "Project"
    return (
        <li className="portfolio-post-item">
            <Link link={link} newTab={link !== "#"}>
                {image === "null"
                    ?
                    (<></>)
                    :
                    (<figure className="portfolio-banner-box">
                        <img src={image} alt={altText} loading="lazy" />
                    </figure>)
                }
                <div className="portfolio-content">
                    <div>
                        <div
                            className={`d-flex align-center portfolio-category ${isProject ? "project" : "case-study"
                                }`}
                        >
                            <p>{category}</p>
                        </div>
                        {
                            isProject ? <></> :
                                <div className="portfolio-meta">
                                    <time dateTime={date}>
                                        Published on{" "}
                                        {new Date(date).toLocaleDateString("en-US", {
                                            month: "short",
                                            day: "numeric",
                                            year: "numeric",
                                        })}
                                    </time>
                                </div>
                        }
                        <h3 className="h3 portfolio-item-title">{title}</h3>
                        <p className="portfolio-text">{description}</p>
                    </div>
                    {!isProject &&

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginTop: "2rem",
                            }}
                        >
                            <Header headerTitle="Read More" headerElementName="h5" headerClassName="custom-link" />


                        </div>
                    }
                </div>
            </Link>
        </li>
    );
};
