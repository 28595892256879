import React from "react";
import { About } from "./About";
import { Resume } from "./Resume";
import { Contact } from "./Contact";
import { Portfolio } from "./Portfolio";

export interface Pages {
  uId: string;
  content: JSX.Element;
}

export const pages: Pages[] = [
  {
    uId: "about",
    content: <About />,
  },
  {
    uId: "resume",
    content: <Resume />,
  },
  {
    uId: "portfolio",
    content: <Portfolio />,
  },
  {
    uId: "contact",
    content: <Contact />,
  },
];
