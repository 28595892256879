import React from "react";
import { CommonPageTemplate } from "../common/components/CommonPageTemplate";

import data from "../data/portfolio.json";
import { PortfolioCard } from "../common/components/PortfolioCard";
import { portfolioTabs } from "../data/tabItems";
import { Button } from "../common/components/Button";

export const Portfolio = () => {
  const [filterQuery, setFilterQuery] = React.useState("all")
  return (
    <CommonPageTemplate
      articleTitle="Portfolio"
      articleClassName="portfolio"
      isActive={true}
    >
      <section className="portfolio-posts">
        <div className="d-flex">
          {portfolioTabs.map(tabItem => (
            <Button key={tabItem.uId} className={`navbar-link ${filterQuery === tabItem.uId ? "active" : ""}`} onClick={() => setFilterQuery(tabItem.uId)} >{tabItem.title}</Button>
          ))}
        </div>
        <ul className="portfolio-posts-list">
          {data.portfolio.filter(portfolioData => filterQuery === "all" ? true : portfolioData.uId === filterQuery).map((ele) => (
            <PortfolioCard {...ele} key={ele.id} />
          ))}
        </ul>
      </section>
    </CommonPageTemplate>
  );
};
