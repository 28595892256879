import { TabItems } from "../common/components/Tabs";

export const tabItems: TabItems[] = [
  {
    title: "About",
    uId: "about",
  },
  {
    title: "Resume",
    uId: "resume",
  },
  {
    title: "Portfolio",
    uId: "portfolio",
  },
  {
    title: "Contact",
    uId: "contact",
  },
];

export const portfolioTabs: TabItems[] = [
  {
    title: "All",
    uId: "all",
  },
  {
    title: "Case Studies",
    uId: "caseStudies",
  },
  {
    title: "Projects",
    uId: "project",
  }
]
