// Loader.tsx
import React from "react";
import loaderImage from "../assets/svg/Cat.svg";

export const FullScreenLoader = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        backdropFilter: "blur(15px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "self-end",
        zIndex: 1000,
        opacity: 1,
        transition: "opacity 0.1s ease-in-out",
      }}
    >
      <img src={loaderImage} alt="Loading..." width={250} />
    </div>
  );
};
